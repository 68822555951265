import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/estimate',
    name: 'estimate',
    component: () => import('../views/estimate.vue'),
    meta: { title: '风险评估' }
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/result.vue'),
    meta: { title: '评估结果' }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/record.vue'),
    meta: { title: '评估记录' }
  },
  {
    path: '/record_estimate',
    name: 'record_estimate',
    component: () => import('../views/record_estimate.vue'),
    meta: { title: '记录评估' }
  },
  {
    path: '/record_result',
    name: 'record_result',
    component: () => import('../views/record_result.vue'),
    meta: { title: '记录结果' }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.query.token) {
    localStorage.setItem('token',to.query.token);
  }
  next();
})

export default router
