import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import Vant from 'vant';
import 'vant/lib/index.css'


Vue.config.productionTip = false
Vue.prototype.axios=axios
Vue.use(Vant);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

! function (n) {
  var e = n.document,
      t = e.documentElement,
      i = 750,
      d = i / 100,
      o = "orientationchange" in n ? "orientationchange" : "resize",
      a = function () {
          var n = t.clientWidth || 320;
          n > 750 && (n = 750), t.style.fontSize = n / d + "px"
      };
  e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener("DOMContentLoaded", a, !1))
}(window);